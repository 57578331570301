import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import "./UpdateDogModal.css";

interface UpdateDogModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  dog: any;
  onDogUpdated: (updatedDog: any) => void;
}

const UpdateDogModal: React.FC<UpdateDogModalProps> = ({ isOpen, onRequestClose, dog, onDogUpdated }) => {
  const [updatedDog, setUpdatedDog] = useState<any>(dog);
  const [breeds, setBreeds] = useState<{ label: string, value: string }[]>([]);
  const [newProfilePhoto, setNewProfilePhoto] = useState<File | null>(null);

  useEffect(() => {
    setUpdatedDog(dog);
  }, [dog]);

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-dog-breeds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setBreeds(data.breeds.map((breed: { breed_id: number, name: string }) => ({ label: breed.name, value: breed.name })));
        } else {
          console.error('Failed to fetch dog breeds');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchBreeds();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (e.target instanceof HTMLInputElement && e.target.files && name === 'profilePhoto') {
      setNewProfilePhoto(e.target.files[0]);
    } else {
      setUpdatedDog({ ...updatedDog, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      let imageUrl = updatedDog.profilePhotoUrl;

      // Upload de la nouvelle photo de profil si nécessaire
      if (newProfilePhoto) {
        const presignedResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-upload-url`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            file_name: newProfilePhoto.name,
            file_type: newProfilePhoto.type
          })
        });

        if (!presignedResponse.ok) {
          toast.error('Failed to get presigned URL for upload');
          return;
        }

        const presignedData = await presignedResponse.json();
        const formDataForS3 = new FormData();
        Object.keys(presignedData.fields).forEach(key => {
          formDataForS3.append(key, presignedData.fields[key]);
        });
        formDataForS3.append('file', newProfilePhoto);

        const uploadResponse = await fetch(presignedData.url, {
          method: 'POST',
          body: formDataForS3
        });

        if (uploadResponse.ok) {
          imageUrl = `${presignedData.url}${presignedData.fields.key}`;
        } else {
          toast.error('Failed to upload the new profile photo');
          return;
        }
      }

      // Conversion "Oui"/"Non" en booléen
      const sterilizedBoolean = updatedDog.sterilized === 'Oui' ? true : (updatedDog.sterilized === 'Non' ? false : null);
      const sociableWithDogsBoolean = updatedDog.sociableWithDogs === 'Oui' ? true : (updatedDog.sociableWithDogs === 'Non' ? false : null);
      const hasInsuranceBoolean = updatedDog.hasInsurance === 'Oui' ? true : (updatedDog.hasInsurance === 'Non' ? false : null);

      // Préparation des données pour le backend en snake_case
      const dogDataToUpdate = {
        dog_id: updatedDog.dogId,
        name: updatedDog.name || null,
        breed: updatedDog.breed || null,
        birthdate: updatedDog.birthdate || null,
        information: updatedDog.information || null,
        profile_photo_url: imageUrl || null,
        sexe: updatedDog.sexe || null,
        chip_number: updatedDog.chipNumber || null,
        sterilized: sterilizedBoolean,
        size: updatedDog.size || null,
        weight: updatedDog.weight || null,
        sociable_with_dogs: sociableWithDogsBoolean,
        sociable_with_humans: updatedDog.sociableWithHumans || null,
        dietary_habits: updatedDog.dietaryHabits || null,
        energy_level: updatedDog.energyLevel || null,
        known_fears: updatedDog.knownFears || null,
        known_illnesses: updatedDog.knownIllnesses || null,
        specific_medical_needs: updatedDog.specificMedicalNeeds || null,
        last_deworming: updatedDog.lastDeworming || null,
        has_insurance: hasInsuranceBoolean
      };

      const updateResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-dog-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(dogDataToUpdate)
      });

      if (!updateResponse.ok) {
        toast.error('Failed to update dog profile');
        return;
      }

      const dogDetailsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dogs/${updatedDog.dogId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!dogDetailsResponse.ok) {
        toast.error('Unable to fetch updated dog details');
        return;
      }

      const fullUpdatedDog = await dogDetailsResponse.json();

      toast.success('Dog profile updated successfully!');
      onDogUpdated(fullUpdatedDog);
      onRequestClose();
    } catch (error) {
      toast.error('An error occurred while updating the dog profile');
      console.error('Error:', error);
    }
  };

  if (!dog) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modifier les informations du chien"
      className="modal-update-dog-content"
      overlayClassName="modal-update-dog-overlay"
    >
      <h2>Modifier les informations du chien</h2>
      <form>
        <div className="form-group">
          <label>Nom</label>
          <input type="text" name="name" value={updatedDog?.name || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Race</label>
          <select name="breed" value={updatedDog?.breed || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une race</option>
            {breeds.map((breed) => (
              <option key={breed.value} value={breed.value}>{breed.label}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Date de naissance</label>
          <input type="date" name="birthdate" value={updatedDog?.birthdate || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Informations</label>
          <textarea name="information" value={updatedDog?.information || ''} onChange={handleChange}></textarea>
        </div>

        <div className="form-group">
          <label>Sexe</label>
          <select name="sexe" value={updatedDog?.sexe || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner le sexe</option>
            <option value="Mâle">Mâle</option>
            <option value="Femelle">Femelle</option>
          </select>
        </div>

        <div className="form-group">
          <label>Numéro de puce</label>
          <input type="text" name="chipNumber" value={updatedDog?.chipNumber || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Stérilisé/Castré</label>
          <select name="sterilized" value={updatedDog?.sterilized === true ? 'Oui' : updatedDog?.sterilized === false ? 'Non' : ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une option</option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </div>

        <div className="form-group">
          <label>Taille</label>
          <select name="size" value={updatedDog?.size || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner la taille</option>
            <option value="Petit">Petit</option>
            <option value="Moyen">Moyen</option>
            <option value="Grand">Grand</option>
          </select>
        </div>

        <div className="form-group">
          <label>Poids (en kg)</label>
          <input type="number" name="weight" value={updatedDog?.weight || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Sociable avec les autres chiens</label>
          <select name="sociableWithDogs" value={updatedDog?.sociableWithDogs === true ? 'Oui' : updatedDog?.sociableWithDogs === false ? 'Non' : ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une option</option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </div>

        <div className="form-group">
          <label>Sociable avec les humains</label>
          <select name="sociableWithHumans" value={updatedDog?.sociableWithHumans || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une option</option>
            <option value="Enfants">Enfants</option>
            <option value="Adultes">Adultes</option>
            <option value="Inconnus">Inconnus</option>
            <option value="Tous">Tous</option>
          </select>
        </div>

        <div className="form-group">
          <label>Habitudes alimentaires</label>
          <input type="text" name="dietaryHabits" value={updatedDog?.dietaryHabits || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Niveau d'énergie</label>
          <select name="energyLevel" value={updatedDog?.energyLevel || ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une option</option>
            <option value="Faible">Faible</option>
            <option value="Moyen">Moyen</option>
            <option value="Élevé">Élevé</option>
          </select>
        </div>

        <div className="form-group">
          <label>Peurs connues</label>
          <input type="text" name="knownFears" value={updatedDog?.knownFears || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Maladies connues</label>
          <input type="text" name="knownIllnesses" value={updatedDog?.knownIllnesses || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Besoins médicaux spécifiques</label>
          <input type="text" name="specificMedicalNeeds" value={updatedDog?.specificMedicalNeeds || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Dernier déparasitage</label>
          <input type="date" name="lastDeworming" value={updatedDog?.lastDeworming || ''} onChange={handleChange} />
        </div>

        <div className="form-group">
          <label>Assurance animale</label>
          <select name="hasInsurance" value={updatedDog?.hasInsurance === true ? 'Oui' : updatedDog?.hasInsurance === false ? 'Non' : ''} onChange={handleChange}>
            <option value="" disabled>Sélectionner une option</option>
            <option value="Oui">Oui</option>
            <option value="Non">Non</option>
          </select>
        </div>

        <div className="form-group">
          <label>Photo de profil</label>
          <input type="file" name="profilePhoto" onChange={handleChange} />
          {updatedDog?.profilePhotoUrl && (
            <div className="current-photo-preview">
              <p>Photo actuelle :</p>
              <img src={updatedDog.profilePhotoUrl} alt={updatedDog.name} />
            </div>
          )}
        </div>

        <div className="button-group">
          <button type="button" onClick={handleSave}>Sauvegarder</button>
          <button type="button" onClick={onRequestClose}>Annuler</button>
        </div>
      </form>
    </Modal>
  );
};

export default UpdateDogModal;
