/* eslint-disable jsx-a11y/img-redundant-alt */
// src/components/SocialProof/SocialProof.tsx

import React, { useEffect, useRef } from 'react';
import pawIcon from '../../assets/patte-brun.png';
import quoteIcon from '../../assets/guillemets-rose.png';
import smileIcon from '../../assets/content.png';
import './SocialProof.css';

const SocialProof: React.FC = () => {
  const testimonialsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const testimonials = testimonialsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    testimonials.forEach((testimonial) => {
      if (testimonial) {
        observer.observe(testimonial);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const testimonials = [
    {
      image: 'https://rintintin-bucket.s3.amazonaws.com/24ed6367-8b7a-42f0-b6e6-f3016213431b.JPG',
      text: "Grâce à Rintintin, j'ai trouvé l'éducateur idéal pour mon chien. Le service est impeccable !",
      dogName: 'Lady',
      ownerName: 'Yann',
    },
    {
      image: 'https://rintintin-bucket.s3.amazonaws.com/ba8bc13e-10d4-4e83-bc7d-54b178e7b19c.jpeg',
      text: "J'étais inquiète de pas m'en sortir avec Milly, mais l'éducateur a été fantastique et m'a grandement rassuré.",
      dogName: 'Milly',
      ownerName: 'Aksel',
    },
    {
      image: 'https://rintintin-bucket.s3.amazonaws.com/12edb79e-2988-49ee-a92a-675686373681.jpg',
      text: "Nous avons trouvé un vrai pro pour Charlie. Nous recommandons vivement Rintintin pour tous les propriétaires de chiens !",
      dogName: 'Charlie',
      ownerName: 'Marie & Julien',
    },
  ];

  return (
    <div className="social-proof">
      <h2>Ils témoignent en famille</h2>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <div
            className="testimonial"
            key={index}
            ref={(el) => {
              if (el) testimonialsRef.current[index] = el;
            }}
          >
            <div className="testimonial-content">
              <div className="testimonial-quote-icon">
                <img src={quoteIcon} alt="Icône guillemets" />
              </div>
              <div className="testimonial-image-container">
                <img
                  src={testimonial.image}
                  alt={`Photo de ${testimonial.ownerName}`}
                  className="testimonial-image"
                />
              </div>
              <div className="testimonial-text-container">
                <p className="testimonial-text">"{testimonial.text}"</p>
                <div className="testimonial-footer">
                  <img src={pawIcon} alt="Icône patte" className="icon" />
                  <span className="dog-name">{testimonial.dogName}</span>
                  <img src={smileIcon} alt="Icône sourire" className="icon" />
                  <span className="owner-name">{testimonial.ownerName}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialProof;
