import React from 'react';
import { TextInputField, Pane, Select } from 'evergreen-ui';

interface StepProps {
  formData: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const AddDogStep3: React.FC<StepProps> = ({ formData, handleInputChange }) => (
  <Pane>
    <Pane>
      <label>Taille</label>
      <Select
        name="size"
        value={formData.size}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner la taille</option>
        <option value="Petit">Petit</option>
        <option value="Moyen">Moyen</option>
        <option value="Grand">Grand</option>
      </Select>
    </Pane>
    <Pane>
      <label>Sociable avec les autres chiens</label>
      <Select
        name="sociable_with_dogs"
        value={formData.sociable_with_dogs}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner une option</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </Select>
    </Pane>
    <Pane>
      <label>Sociable avec les humains</label>
      <Select
        name="sociable_with_humans"
        value={formData.sociable_with_humans}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner une option</option>
        <option value="Enfants">Enfants</option>
        <option value="Adultes">Adultes</option>
        <option value="Inconnus">Inconnus</option>
        <option value="Tous">Tous</option>
      </Select>
    </Pane>
    <TextInputField
      label="Habitudes alimentaires"
      placeholder="Habitudes alimentaires"
      name="dietary_habits"
      value={formData.dietary_habits}
      onChange={handleInputChange}
    />
    <Pane>
      <label>Niveau d'énergie</label>
      <Select
        name="energy_level"
        value={formData.energy_level}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner une option</option>
        <option value="Faible">Faible</option>
        <option value="Moyen">Moyen</option>
        <option value="Élevé">Élevé</option>
      </Select>
    </Pane>
    <TextInputField
      label="Peurs connues"
      placeholder="Peurs connues"
      name="known_fears"
      value={formData.known_fears}
      onChange={handleInputChange}
    />
    <TextInputField
      label="Informations supplémentaires"
      placeholder="Informations sur le chien"
      name="information"
      value={formData.information}
      onChange={handleInputChange}
    />
  </Pane>
);

export default AddDogStep3;
