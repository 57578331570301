// PasswordForgottenPage.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './PasswordForgotten.css';
import sendEmail from '../../utils/emailForm';

const PasswordForgottenPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handlePasswordReset = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await sendEmail({
        toEmail: 'contact@rintintin-booking.com',
        subject: 'Réinitialisation du mot de passe',
        htmlContent: `
          <p>L'utilisateur avec l'adresse e-mail ${email} a demandé une réinitialisation du mot de passe.</p>
        `,
      });
      toast.success('Un email a été envoyé à l\'administrateur.');
      navigate('/thank-you');
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email :', error);
      toast.error('Erreur lors de l\'envoi de l\'email.');
    }
  };

  return (
    <div className="password-forgotten-container">
      <div className="password-forgotten-image"></div>
      <div className="password-forgotten-form-container">
        <h2>Réinitialiser le mot de passe</h2>
        <form onSubmit={handlePasswordReset}>
          <label htmlFor="email">Adresse e-mail *</label>
          <input
            type="email"
            id="email"
            placeholder="Votre adresse e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <button type="submit" className="reset-button">
            Réinitialiser le mot de passe
          </button>

          <div className="separator">
            <span>OU</span>
          </div>

          <button type="button" className="back-button" onClick={() => navigate('/login')}>
            Retour à la connexion
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordForgottenPage;
