// AddDogModal.tsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import './AddDogModal.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';

import AddDogStep1 from './AddDogStep1';
import AddDogStep2 from './AddDogStep2';
import AddDogStep3 from './AddDogStep3';

Modal.setAppElement('#root');

interface AddDogModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onDogAdded: (dog: any) => void;
}

const AddDogModal: React.FC<AddDogModalProps> = ({ isOpen, onRequestClose, onDogAdded }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: '',
    breed: '',
    birthdate: '',
    profile_photo: null as File | null,
    information: '',
    sexe: '',
    chip_number: '',
    sterilized: '',
    size: '',
    weight: '',
    sociable_with_dogs: '',
    sociable_with_humans: '',
    dietary_habits: '',
    energy_level: '',
    known_fears: '',
    known_illnesses: '',
    specific_medical_needs: '',
    last_deworming: '',
    has_insurance: ''
  });
  const [breeds, setBreeds] = useState<{ label: string, value: string }[]>([]);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-dog-breeds`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setBreeds(data.breeds.map((breed: { breed_id: number, name: string }) => ({ label: breed.name, value: breed.name })));
        } else {
          console.error('Failed to fetch dog breeds');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchBreeds();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, files } = e.target as HTMLInputElement & HTMLSelectElement;
    if (name === 'profile_photo' && files) {
      setFormData({ ...formData, profile_photo: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNextStep = () => {
    setCurrentStep(prev => Math.min(prev + 1, 3));
  };

  const handlePrevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleAddDog = async () => {
    if (!formData.profile_photo) {
      toast.error('Veuillez sélectionner une photo pour le chien.');
      return;
    }

    if (!formData.sexe) {
      toast.error('Veuillez indiquer le sexe du chien.');
      return;
    }

    try {
      // 1. Obtenir l'URL signée pour l'upload
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-upload-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          file_name: formData.profile_photo.name,
          file_type: formData.profile_photo.type
        })
      });

      const presignedData = await response.json();

      // 2. Upload du fichier vers S3
      const formDataForS3 = new FormData();
      Object.keys(presignedData.fields).forEach(key => {
        formDataForS3.append(key, presignedData.fields[key]);
      });
      formDataForS3.append('file', formData.profile_photo);

      const uploadResponse = await fetch(presignedData.url, {
        method: 'POST',
        body: formDataForS3
      });

      if (!uploadResponse.ok) {
        toast.error('Échec du téléchargement du fichier vers S3.');
        return;
      }

      const imageUrl = `${presignedData.url}${presignedData.fields.key}`;

      // 3. Créer le profil du chien
      const profileResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-dog-profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          user_id: user.user_id,
          name: formData.name,
          breed: formData.breed,
          birthdate: formData.birthdate,
          information: formData.information,
          profile_photo_url: imageUrl,
          sexe: formData.sexe,
          chip_number: formData.chip_number || null,
          sterilized: formData.sterilized || null,
          size: formData.size || null,
          weight: formData.weight || null,
          sociable_with_dogs: formData.sociable_with_dogs || null,
          sociable_with_humans: formData.sociable_with_humans || null,
          dietary_habits: formData.dietary_habits || null,
          energy_level: formData.energy_level || null,
          known_fears: formData.known_fears || null,
          known_illnesses: formData.known_illnesses || null,
          specific_medical_needs: formData.specific_medical_needs || null,
          last_deworming: formData.last_deworming || null,
          has_insurance: formData.has_insurance || null
        })
      });

      if (!profileResponse.ok) {
        toast.error('Échec de la création du profil du chien.');
        return;
      }

      const data = await profileResponse.json();
      const dogId = data.dog_id; // Supposons que l'API retourne `dog_id`

      // 4. Récupérer les informations complètes du chien créé
      const dogDetailsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dogs/${dogId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!dogDetailsResponse.ok) {
        toast.error("Impossible de récupérer les informations complètes du chien.");
        return;
      }

      const fullDogData = await dogDetailsResponse.json(); 
      // fullDogData devrait contenir un objet avec toutes les propriétés, y compris profilePhotoUrl

      toast.success('Profil du chien créé avec succès !');
      onDogAdded(fullDogData);

      // Réinitialiser le formulaire et fermer la modale
      setFormData({
        name: '',
        breed: '',
        birthdate: '',
        profile_photo: null,
        information: '',
        sexe: '',
        chip_number: '',
        sterilized: '',
        size: '',
        weight: '',
        sociable_with_dogs: '',
        sociable_with_humans: '',
        dietary_habits: '',
        energy_level: '',
        known_fears: '',
        known_illnesses: '',
        specific_medical_needs: '',
        last_deworming: '',
        has_insurance: ''
      });
      setCurrentStep(1);
      onRequestClose();

    } catch (error) {
      console.error('Error:', error);
      toast.error('Une erreur est survenue lors de la création du profil du chien.');
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <AddDogStep1 formData={formData} handleInputChange={handleInputChange} breeds={breeds} />;
      case 2:
        return <AddDogStep2 formData={formData} handleInputChange={handleInputChange} />;
      case 3:
        return <AddDogStep3 formData={formData} handleInputChange={handleInputChange} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="sm" fullWidth>
      <DialogTitle>Ajouter un chien</DialogTitle>
      <DialogContent>
        <Stepper activeStep={currentStep - 1} alternativeLabel>
          {[1, 2, 3].map((label) => (
            <Step key={label}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
        {renderStepContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="secondary">
          Fermer
        </Button>
        {currentStep > 1 && (
          <Button onClick={handlePrevStep} color="primary">
            Précédent
          </Button>
        )}
        {currentStep < 3 && (
          <Button onClick={handleNextStep} color="primary">
            Suivant
          </Button>
        )}
        {currentStep === 3 && (
          <Button onClick={handleAddDog} color="primary">
            Ajouter un chien
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddDogModal;
