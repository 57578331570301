import React from 'react';
import './BulletPoint.css';

interface BulletPointProps {
  title: string;
  description: string;
  iconUrl: string;
}

const BulletPoint: React.FC<BulletPointProps> = ({ title, description, iconUrl }) => {
  return (
    <div className="bullet-point-item">
      <img src={iconUrl} alt={title} className="bullet-point-icon" />
      <h3 className="bullet-point-title">{title}</h3>
      <p className="bullet-point-description-text">{description}</p>
    </div>
  );
};

export default BulletPoint;
