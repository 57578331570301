// GeneralSearch.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TrainerCard from '../../components/TrainerCard/TrainerCard';
import './GeneralSearch.css';

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  is_professional: boolean; // Ajout de la propriété
}

const GeneralSearch: React.FC = () => {
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrainers();
  }, []);

  const fetchTrainers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-trainers`, {
        params: { limit: 4 },
      });
      setTrainers(response.data.trainers);
    } catch (error) {
      console.error('Error fetching trainers:', error);
    }
  };

  const handleSeeMoreTrainers = () => {
    navigate('/trainers');
  };

  return (
    <div className="general-search-container">
      {/* Section Trainers */}
      <section className="section-container">
        <h2 className="section-title">Nos éducateurs canins</h2>
        <div className="cards-container">
          {trainers.map((trainer, index) => {
            // Calculer la classe en fonction de l'index
            const className = `trainer-${index + 1}`;
            return (
              <TrainerCard
                key={trainer.id}
                className={className} // Passer la classe personnalisée au composant
                slug={trainer.slug}
                profilePhotoUrl={trainer.profile_photo_url}
                name={trainer.name}
                ville={trainer.ville}
                description={trainer.description}
                isProfessional={trainer.is_professional} // Ajout de la propriété
              />
            );
          })}
        </div>
        <div className="see-more-btn-container">
          <button className="see-more-btn" onClick={handleSeeMoreTrainers}>
            Voir plus d'éducateurs
          </button>
        </div>
      </section>
    </div>
  );
};

export default GeneralSearch;
