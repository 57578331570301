// src/components/BulletPointDescription/BulletPointDescription.tsx

import React, { useEffect, useRef } from 'react';
import BulletPoint from '../BulletPoint/BulletPoint';
import './BulletPointDescription.css';

import iconGPS from '../../assets/GPS.png';
import iconPaw from '../../assets/patte-multi.png';
import iconSchedule from '../../assets/content.png';

const BulletPointDescription: React.FC = () => {
  const bulletPointsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const animateOnScroll = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(animateOnScroll, options);

    bulletPointsRef.current.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const bulletPoints = [
    {
      title: 'Découvre les professionnels autour de chez toi',
      description: 'Accède facilement aux meilleurs éducateurs  près de chez toi.',
      iconUrl: iconGPS,
    },
    {
      title: 'Partage toutes les infos essentielles de ton toutou',
      description: 'Simplifie la vie des professionnels en partageant les détails importants de ton chien.',
      iconUrl: iconPaw,
    },
    {
      title: 'Simplifie toi la vie',
      description: 'Trouve et réserve simplement des séances personnalisées avec des éducateurs canins qualifiés.',
      iconUrl: iconSchedule,
    },
  ];

  return (
    <div className="bullet-point-description">
      <h2 className="bullet-point-heading">C'est super simple !</h2>
      <p className="bullet-point-subheading">Les meilleurs professionnels du secteur canin</p>
      <div className="bullet-point-list">
        {bulletPoints.map((bullet, index) => (
          <div
            key={index}
            ref={(el) => {
              if (el) bulletPointsRef.current[index] = el;
            }}
            className="bullet-point-wrapper"
          >
            <BulletPoint
              title={bullet.title}
              description={bullet.description}
              iconUrl={bullet.iconUrl}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BulletPointDescription;
