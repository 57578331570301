/* eslint-disable jsx-a11y/img-redundant-alt */
// TrainerCard.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TrainerCard.css';

interface TrainerCardProps {
  slug: string;
  profilePhotoUrl: string;
  name: string;
  ville: string;
  description: string;
  isProfessional: boolean; 
  className?: string; // Ajouter la prop className optionnelle
}

const TrainerCard: React.FC<TrainerCardProps> = ({
  slug,
  profilePhotoUrl,
  name,
  ville,
  description,
  isProfessional, // Utilisation de la propriété
  className, // Récupérer la prop className
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/pro/${slug}`);
  };

  return (
    <div
      className={`trainer-card ${className}`} // Appliquer la classe personnalisée ici
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
    >
      <div className="trainer-card-image-wrapper">
        <img src={profilePhotoUrl} alt={`Photo de ${name}`} className="trainer-card-image" />
        {isProfessional && <span className="trainer-badge">Pro</span>} {/* Ajout du badge */}
      </div>
      <div className="trainer-card-content">
        <h2 className="trainer-card-title">{name}</h2>
        <p className="trainer-card-ville">{ville}</p>
        <p className="trainer-card-description">{description}</p>
      </div>
    </div>
  );
};

export default TrainerCard;
