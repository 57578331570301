import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import texts from '../../texts.json';
import logo from '../../assets/logo.png'; // Import du logo

const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const [isFloating, setIsFloating] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFloating(true);
      } else {
        setIsFloating(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <nav className={`navbar ${isFloating ? 'floating' : 'normal'} ${isExpanded ? 'expanded' : ''}`}>
      <div className="logo">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <img src={logo} alt="Rintintin Logo" className="logo-image" />
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <div className="nav-links">
        <Link to="/trainers" className="nav-link">{texts.ourProfesionals}</Link>
        <Link to="/about" className="nav-link">À propos</Link>
        <a href="https://d14e5fa6.sibforms.com/serve/MUIFAD207ccX5oMui0at721djKI_keyFtoeBXBcBdV7lKP1HJoAE258rc0PbUxAGrz2fzLjgtRdyT_H4Y1HvPNGdvVJ0esP_CMozTgN1QW6vmYpsHE0pPQt5J1Xc6D9GiKDIx7Jjjh7oN_M5SK016tUKkCGlbr1RRtSqO6sYj7nHXhMqKyDqKWiFlFUkHTLbya2VsJ6OHCbH7gR0" target="_blank" rel="noopener noreferrer" className="nav-link">
          Newsletter
        </a>
      </div>
      <div className="buttons">
        <a href="https://rintintin-backoffice.netlify.app/login" target="_blank" rel="noopener noreferrer">
          <button className="btn outline">{texts.iAmAPro}</button>
        </a>
        {isAuthenticated ? (
          <div className="user-menu">
            <img src={user?.profile_photo_url || 'https://img.freepik.com/vecteurs-premium/photo-profil-avatar-homme-illustration-vectorielle_268834-538.jpg'} alt="Profile" className="profile-pic" />
            <div className="dropdown">
              <button className="dropbtn">☰</button>
              <div className="dropdown-content">
                <Link to="/profile">Mon profil</Link>
                <Link to="/my-dog">Mon chien</Link>
                <Link to="/reservations">Mes reservations</Link>
                <button onClick={logout}>Se déconnecter</button>
              </div>
            </div>
          </div>
        ) : (
          <Link to="/login">
            <button className="btn filled">{texts.login}</button>
          </Link>
        )}
      </div>
      {isExpanded && (
  <div className="menu-content">
    {isAuthenticated ? (
      <>
        <div className="menu-item">
          <Link to="/profile" onClick={toggleMenu}>Mon profil</Link>
        </div>
        <div className="menu-item">
          <Link to="/my-dog" onClick={toggleMenu}>Mon chien</Link>
        </div>
        <div className="menu-item">
          <Link to="/reservations" onClick={toggleMenu}>Mes réservations</Link>
        </div>
        <div className="menu-item" onClick={() => { logout(); toggleMenu(); }}>
          <button className="btn link-button">Se déconnecter</button>
        </div>
      </>
    ) : (
      <>
        <div className="menu-item">
          <Link to="/login" onClick={toggleMenu}>{texts.login}</Link>
        </div>
        <div className="menu-item">
          <a
            href="https://rintintin-backoffice.netlify.app/login"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleMenu}
            className="external-link"
          >
            {texts.iAmAPro}
          </a>
        </div>
        <div className="menu-item">
          <a
            href="https://d14e5fa6.sibforms.com/serve/MUIFAD207ccX5oMui0at721djKI_keyFtoeBXBcBdV7lKP1HJoAE258rc0PbUxAGrz2fzLjgtRdyT_H4Y1HvPNGdvVJ0esP_CMozTgN1QW6vmYpsHE0pPQt5J1Xc6D9GiKDIx7Jjjh7oN_M5SK016tUKkCGlbr1RRtSqO6sYj7nHXhMqKyDqKWiFlFUkHTLbya2VsJ6OHCbH7gR0"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggleMenu}
            className="external-link"
          >
            Newsletter
          </a>
        </div>
      </>
    )}
    <div className="menu-item">
      <Link to="/trainers" onClick={toggleMenu}>Nos professionnels</Link>
    </div>
    <div className="menu-item">
      <Link to="/about" onClick={toggleMenu}>À propos</Link>
    </div>
  </div>
)}
    </nav>
  );
};

export default Navbar;
