/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import { ClipLoader } from 'react-spinners';
import './TrainerList.css';
import TrainerCard from '../../components/TrainerCard/TrainerCard';

interface Trainer {
  id: number;
  slug: string;
  name: string;
  description: string;
  profile_photo_url: string;
  ville: string;
  rating: number;
  education_methods: string[];
  specialties: string[];
  services: string[];
  is_professional: boolean;
}

const TrainerList: React.FC = () => {
  const location = useLocation();
  const { address } = location.state || {};
  const [trainers, setTrainers] = useState<Trainer[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTrainers(address);
  }, [address]);

  const fetchTrainers = async (address?: string) => {
    try {
      setLoading(true);
      const params: any = {};
      if (address) params.address = address;

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-trainers`,
        { params }
      );

      const fetchedTrainers: Trainer[] = response.data.trainers;

      // Calculer le score de chaque entraîneur
      const scoredTrainers = fetchedTrainers.map((trainer) => ({
        ...trainer,
        infoScore: calculateInfoScore(trainer),
      }));

      // Trier par score décroissant
      const sortedTrainers = scoredTrainers.sort((a, b) => {
        if (a.is_professional !== b.is_professional) {
          // Si a est pro et b non, a > b donc -1 pour que a apparaisse avant
          return b.is_professional ? 1 : -1;
        }
        // Ici les deux ont la même valeur pour is_professional, on trie par infoScore
        return b.infoScore - a.infoScore;
      });

      setTrainers(sortedTrainers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setLoading(false);
    }
  };

  const calculateInfoScore = (trainer: Trainer): number => {
    let score = 0;

    // Ajouter des points pour chaque champ non vide ou pertinent
    if (trainer.description) score++;
    if (trainer.profile_photo_url) score += 10;
    if (trainer.ville) score++;
    if (trainer.education_methods?.length) score += trainer.education_methods.length;
    if (trainer.specialties?.length) score += trainer.specialties.length;
    if (trainer.services?.length) score += trainer.services.length;

    return score;
  };

  return (
    <div className="trainer-list-container">
      <SearchBar withMarginTop={false} variant="second-usage" onSearch={fetchTrainers} />
      <div className="content-container">
        <div className="trainer-cards">
          {loading ? (
            <ClipLoader size={50} color={'#e0b127'} loading={loading} />
          ) : (
            trainers.map((trainer) => (
              <TrainerCard
                key={trainer.id}
                slug={trainer.slug}
                profilePhotoUrl={trainer.profile_photo_url}
                name={trainer.name}
                ville={trainer.ville}
                description={trainer.description}
                isProfessional={trainer.is_professional} // Ajout du champ
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerList;
