// SignupAddDogPage.tsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignupAddDogPage.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../context/AuthContext';

interface Breed {
  breed_id: number;
  name: string;
}

const SignupAddDogPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(''); // Ajout du numéro de téléphone
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [dogName, setDogName] = useState('');
  const [breed, setBreed] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [breeds, setBreeds] = useState<Breed[]>([]);

  const navigate = useNavigate();
  const { login, user } = useAuth();

  useEffect(() => {
    const fetchBreeds = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-dog-breeds`
        );
        setBreeds(response.data.breeds);
      } catch (error) {
        console.error('Error fetching dog breeds:', error);
      }
    };

    fetchBreeds();
  }, []);

  const handleNext = async (event: React.FormEvent) => {
    event.preventDefault();
    if (step === 1) {
      if (password !== confirmPassword) {
        setPasswordError('Les mots de passe ne correspondent pas.');
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/create-user`,
          {
            name: name,
            lastname: lastname,
            email: email,
            password: password,
            phone: phone, // Inclure le numéro de téléphone dans la requête
          }
        );
        localStorage.setItem('token', response.data.token);
        login(response.data.token);
        toast.success('Compte créé avec succès !');
        setStep(2);
      } catch (error: any) {
        console.error('Error signing up:', error);
        toast.error(
          'Erreur lors de la création du compte : ' +
            (error.response?.data?.message || 'Erreur inconnue')
        );
      }
    } else if (step === 2) {
      const data = {
        name: dogName,
        breed: breed,
        user_id: user.user_id,
        birthdate: birthDate || null,
      };

      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/create-dog-profile`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        toast.success('Chien ajouté avec succès !');
        navigate('/');
      } catch (error) {
        toast.error("Erreur lors de l'ajout du chien : Erreur inconnue");
        console.error('Error adding dog:', error);
      }
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (confirmPassword && e.target.value !== confirmPassword) {
      setPasswordError('Les mots de passe ne correspondent pas');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    if (password && e.target.value !== password) {
      setPasswordError('Les mots de passe ne correspondent pas');
    } else {
      setPasswordError('');
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    // Optionnel : ajouter une validation du numéro de téléphone ici
  };

  const handleSkip = () => {
    navigate('/');
  };

  return (
    <div className="signup-container">
      <div className="signup-image"></div>
      <div className="signup-form-container">
        {step === 1 && (
          <div className="form-content">
            <h2>Créer un compte</h2>
            <form onSubmit={handleNext}>
              <label htmlFor="name">Prénom *</label>
              <input
                type="text"
                id="name"
                placeholder="Prénom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <label htmlFor="lastname">Nom *</label>
              <input
                type="text"
                id="lastname"
                placeholder="Nom"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />

              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="phone">Numéro de téléphone *</label>
              <input
                type="tel"
                id="phone"
                placeholder="Numéro de téléphone"
                value={phone}
                onChange={handlePhoneChange}
                required
              />

              <label htmlFor="password">Mot de passe *</label>
              <input
                type="password"
                id="password"
                placeholder="Mot de passe"
                value={password}
                onChange={handlePasswordChange}
                required
              />

              <label htmlFor="confirmPassword">Confirmer le mot de passe *</label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirmer le mot de passe"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              {passwordError && <p className="error-message">{passwordError}</p>}

              <button type="submit" className="signup-button">
                Créer un compte
              </button>

              <div className="separator">
                <span>OU</span>
              </div>

              <h3>J'ai déjà un compte</h3>
              <button
                type="button"
                className="login-button"
                onClick={() => navigate('/login')}
              >
                Se connecter
              </button>
            </form>
          </div>
        )}
        {step === 2 && (
          <div className="form-content">
            <h2>Ajouter un chien</h2>
            <form onSubmit={handleNext}>
              <label htmlFor="dogName">Nom du chien *</label>
              <input
                type="text"
                id="dogName"
                placeholder="Nom du chien"
                value={dogName}
                onChange={(e) => setDogName(e.target.value)}
                required
              />

              <label htmlFor="breed">Race du chien *</label>
              <select
                id="breed"
                value={breed}
                onChange={(e) => setBreed(e.target.value)}
                required
              >
                <option value="">Sélectionner une race</option>
                {breeds.map((breed) => (
                  <option key={breed.breed_id} value={breed.name}>
                    {breed.name}
                  </option>
                ))}
              </select>

              <label htmlFor="birthDate">Date de naissance</label>
              <input
                type="date"
                id="birthDate"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />

              <button type="submit" className="signup-button">
                Ajouter un chien
              </button>

              <button type="button" className="skip-button" onClick={handleSkip}>
                Faire plus tard
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupAddDogPage;
