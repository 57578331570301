import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import './HomePage.css';
import BulletPointDescription from '../../components/BulletPointDescription/BulletPointDescription';
import SocialProof from '../../components/SocialProof/SocialProof';
import FAQ from '../../components/FAQ/FAQ';

const imageUrls = [
  'https://rintintin-bucket.s3.eu-west-3.amazonaws.com/00001pictureRintintin.jpg',
];

const HomePage: React.FC = () => {
  const [backgroundImage, setBackgroundImage] = useState<string>('');

  useEffect(() => {
    const getRandomImageUrl = () => {
      const randomIndex = Math.floor(Math.random() * imageUrls.length);
      return imageUrls[randomIndex];
    };
    setBackgroundImage(getRandomImageUrl());
  }, []);

  return (
    <div className="home-page">
      <div className="hero-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="hero-overlay">
          <div className="yellow-circle">
            <h2>Simplifiez votre quotidien chouchoutez le leur</h2>
            <div className="search-bar-wrapper-home">
              <SearchBar withMarginTop={true} onSearch={() => {}} />
            </div>
          </div>
        </div>
      </div>

      <div className="white-advance">
        <BulletPointDescription />
      </div>

      <SocialProof />

      <FAQ />
    </div>
  );
};

export default HomePage;
