import React from 'react';
import { TextInputField, Pane, Select } from 'evergreen-ui';

interface StepProps {
  formData: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const AddDogStep2: React.FC<StepProps> = ({ formData, handleInputChange }) => (
  <Pane>
    <TextInputField
      label="Numéro de puce"
      placeholder="Numéro de puce"
      name="chip_number"
      value={formData.chip_number}
      onChange={handleInputChange}
    />
    <Pane>
      <label>Stérilisé/Castré</label>
      <Select
        name="sterilized"
        value={formData.sterilized}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner une option</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </Select>
    </Pane>
    <TextInputField
      label="Poids approximatif (kg)"
      placeholder="Poids en kg"
      name="weight"
      type="number"
      value={formData.weight}
      onChange={handleInputChange}
    />
    <TextInputField
      label="Maladies connues"
      placeholder="Maladies connues"
      name="known_illnesses"
      value={formData.known_illnesses}
      onChange={handleInputChange}
    />
    <TextInputField
      label="Besoins médicaux spécifiques"
      placeholder="Besoins médicaux spécifiques"
      name="specific_medical_needs"
      value={formData.specific_medical_needs}
      onChange={handleInputChange}
    />
    <TextInputField
      label="Dernier déparasitage"
      placeholder="Date du dernier déparasitage"
      type="date"
      name="last_deworming"
      value={formData.last_deworming}
      onChange={handleInputChange}
    />
    <Pane>
      <label>Assurance animale</label>
      <Select
        name="has_insurance"
        value={formData.has_insurance}
        onChange={handleInputChange}
      >
        <option value="" disabled>Sélectionner une option</option>
        <option value="Oui">Oui</option>
        <option value="Non">Non</option>
      </Select>
    </Pane>
  </Pane>
);

export default AddDogStep2;
