// src/components/ReservationList/ReservationList.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ReservationList.css';
import { format, parseISO, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Dog {
  dogId: number;
  name: string;
  breed: string;
}

interface Service {
  serviceId: number;
  serviceName: string;
  location: string;
  question: string;
}

interface Trainer {
  trainerId: number;
  name: string;
  slug: string;
  email: string;
}

interface Booking {
  bookingId: number;
  status: string;
  dateCreated: string;
  clientAnswer: string;
  startTime: string;
  endTime: string;
  date: string;
  dog: Dog;
  service: Service;
  trainer: Trainer;
  userEmail: string;
}

interface ReservationListProps {
  userId: number;
}

const ReservationList: React.FC<ReservationListProps> = ({ userId }) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/get-bookings-user/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        setBookings(response.data.bookings);
        console.log('Réservations récupérées :', response.data.bookings);
      } catch (error) {
        console.error('Erreur lors de la récupération des réservations :', error);
        toast.error('Erreur lors de la récupération des réservations.');
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [userId]);

  const isCancellable = (status: string) => {
    const cancellableStatuses = ['validated', 'pending', 'rescheduled'];
    return cancellableStatuses.includes(status);
  };

  const handleCancelBooking = async (bookingId: number) => {
    if (!window.confirm("Êtes-vous sûr de vouloir annuler cette réservation ?")) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-booking`,
        {
          booking_id: bookingId,
          cancelled_by: 'client',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 200) {
        const { client_email_sent, trainer_email_sent } = response.data;

        // Mettre à jour l'état local pour refléter l'annulation
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.bookingId === bookingId
              ? { ...booking, status: 'cancelled_by_client' }
              : booking
          )
        );

        // Afficher les toasts en fonction de l'envoi des e-mails
        if (client_email_sent) {
          toast.success('Un e-mail de confirmation vous a été envoyé.');
        } else {
          toast.error("Erreur lors de l'envoi de l'e-mail de confirmation au client.");
        }

        if (trainer_email_sent) {
          toast.success('Un e-mail a été envoyé au professionnel.');
        } else {
          toast.error("Erreur lors de l'envoi de l'e-mail au professionnel.");
        }

        // Afficher un toast final si les deux e-mails ont été envoyés avec succès
        if (client_email_sent && trainer_email_sent) {
          toast.success('Réservation annulée et e-mails envoyés avec succès.');
        } else if (client_email_sent || trainer_email_sent) {
          toast.warn(
            "Réservation annulée, mais certains e-mails n'ont pas pu être envoyés."
          );
        }
      } else {
        toast.error("Erreur lors de l'annulation de la réservation.");
      }
    } catch (error: any) {
      console.error("Erreur lors de l'annulation :", error);
      const errorMessage = error.response?.data?.error || "Erreur lors de l'annulation.";
      toast.error(errorMessage);
    }
  };

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString);
    return format(date, 'EEEE d MMMM', { locale: fr });
  };

  const formatTime = (timeString: string) => {
    const time = parse(timeString, 'HH:mm:ss', new Date());
    return format(time, 'HH:mm');
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'validated':
        return 'Validée';
      case 'rescheduled':
        return 'Reprogrammée';
      case 'pending':
        return 'En attente de validation';
      case 'pending_reschedule':
        return 'Reprogrammation en attente';
      case 'declined':
        return 'Refusée';
      case 'cancelled_by_pro':
        return 'Annulée par le professionnel';
      case 'cancelled_by_client':
        return 'Annulée par vous';
      default:
        return status;
    }
  };

  if (loading) {
    return <p>Chargement des réservations...</p>;
  }

  if (bookings.length === 0) {
    return <p>Vous n'avez pas de réservations pour le moment.</p>;
  }

  // Traiter les réservations
  const today = new Date();

  // Initialiser les tableaux de réservations
  const pendingBookings: Booking[] = [];
  const upcomingBookings: Booking[] = [];
  const pastBookings: Booking[] = [];
  const declinedBookings: Booking[] = [];

  // Parcourir toutes les réservations et les affecter à une seule catégorie
  bookings.forEach((booking) => {
    if (booking.status === 'pending' || booking.status === 'pending_reschedule') {
      pendingBookings.push(booking);
    } else if (
      ['declined', 'cancelled_by_pro', 'cancelled_by_client'].includes(booking.status)
    ) {
      declinedBookings.push(booking);
    } else {
      const bookingDate = parseISO(booking.date);
      if (bookingDate >= today) {
        upcomingBookings.push(booking);
      } else {
        pastBookings.push(booking);
      }
    }
  });

  return (
    <div className="reservation-list">
      {/* Réservations à venir */}
      {upcomingBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations à venir</h2>
          {upcomingBookings.map((booking) => (
            <div
              key={booking.bookingId}
              className={`reservation-card ${booking.status}`}
            >
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  {getStatusLabel(booking.status)}
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de{' '}
                  {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
                <div className="reservation-actions">
                  {isCancellable(booking.status) && (
                    <button onClick={() => handleCancelBooking(booking.bookingId)}>
                      Annuler
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Réservations en attente */}
      {pendingBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations en attente</h2>
          {pendingBookings.map((booking) => (
            <div
              key={booking.bookingId}
              className={`reservation-card ${booking.status}`}
            >
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  {getStatusLabel(booking.status)}
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de{' '}
                  {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
                <div className="reservation-actions">
                  {isCancellable(booking.status) && (
                    <button onClick={() => handleCancelBooking(booking.bookingId)}>
                      Annuler
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Réservations passées */}
      {pastBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations passées</h2>
          {pastBookings.map((booking) => (
            <div key={booking.bookingId} className={`reservation-card past`}>
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  {getStatusLabel(booking.status)}
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de{' '}
                  {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
                <div className="reservation-actions">
                  {/* Pas d'action disponible pour les réservations passées */}
                  <span className="no-action">Aucune action disponible</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Réservations annulées ou refusées */}
      {declinedBookings.length > 0 && (
        <div className="booking-section">
          <h2>Réservations annulées ou refusées</h2>
          {declinedBookings.map((booking) => (
            <div
              key={booking.bookingId}
              className={`reservation-card ${booking.status}`}
            >
              <div className="reservation-card-header">
                <h3>{booking.service.serviceName}</h3>
                <span className={`status-badge ${booking.status}`}>
                  {getStatusLabel(booking.status)}
                </span>
              </div>
              <div className="reservation-card-body">
                <p>
                  <strong>Date :</strong> {formatDate(booking.date)} de{' '}
                  {formatTime(booking.startTime)} à {formatTime(booking.endTime)}
                </p>
                <p>
                  <strong>Professionnel :</strong>{' '}
                  <Link to={`/pro/${booking.trainer.slug}`}>
                    {booking.trainer.name}
                  </Link>
                </p>
                <p>
                  <strong>Lieu :</strong> {booking.service.location}
                </p>
                <p>
                  <strong>Chien :</strong> {booking.dog.name} ({booking.dog.breed})
                </p>
                <div className="reservation-actions">
                  {/* Pas d'action disponible pour les réservations annulées ou refusées */}
                  <span className="no-action">Aucune action disponible</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Aucun résultat */}
      {upcomingBookings.length === 0 &&
        pendingBookings.length === 0 &&
        pastBookings.length === 0 &&
        declinedBookings.length === 0 && (
          <p>Vous n'avez pas de réservations pour le moment.</p>
        )}
    </div>
  );
};

export default ReservationList;
