import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";

interface Profession {
  id: number;
  name: string;
  slug: string;
}

interface SearchBarProps {
  onSearch: (address: string, professionId?: number | null) => void;
  withMarginTop?: boolean;
  variant?: string; // Ajout du prop variant
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  withMarginTop = false,
  variant = "default", // Valeur par défaut
}) => {
  const [address, setAddress] = useState("");
  const [selectedProfession, setSelectedProfession] = useState<number | null>(
    null
  );
  const navigate = useNavigate();

  // Professions définies en dur
  const professions: Profession[] = [
    { id: 2, name: "Éducateur canin", slug: "trainers" },
    // Ajoutez d'autres professions si nécessaire
  ];

  const getCoordinates = async (address: string): Promise<[number, number]> => {
    try {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address
      )}&format=json&limit=1`;
      const response = await fetch(url);

      const data = await response.json();
      if (data && data.length > 0) {
        const lat = parseFloat(data[0].lat);
        const lon = parseFloat(data[0].lon);
        return [lat, lon];
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
    return [0, 0];
  };

  const handleSearch = async () => {
    if (selectedProfession === null) {
      navigate("/trainers");
      return;
    }

    const profession = professions.find(
      (prof) => prof.id === selectedProfession
    );
    if (!profession) {
      console.error("Profession non trouvée.");
      return;
    }

    let lat: number | null = null;
    let lon: number | null = null;

    if (address) {
      [lat, lon] = await getCoordinates(address);
    }

    if (lat !== null && lon !== null) {
      navigate(`/${profession.slug}`, {
        state: {
          address,
          coordinates: { lat, lon },
          professionId: selectedProfession,
        },
      });
    } else {
      navigate(`/${profession.slug}`, {
        state: { professionId: selectedProfession },
      });
    }
  };

  return (
    <div className={`search-bar-container ${variant}`}>
      <div className="circle-background"></div>
      <div className="search-bar">
        <div className="search-input-container">
          <label htmlFor="location">Ville</label>
          <input
            id="location"
            type="text"
            placeholder="L'endroit idéal"
            className="search-input"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="search-input-container">
          <label htmlFor="profession">Professionnel</label>
          <select
            id="profession"
            className="search-select"
            value={selectedProfession ?? ""}
            onChange={(e) =>
              setSelectedProfession(
                e.target.value ? Number(e.target.value) : null
              )
            }
          >
            <option value="">Sélectionnez une profession</option>
            {professions.map((profession) => (
              <option key={profession.id} value={profession.id}>
                {profession.name}
              </option>
            ))}
          </select>
        </div>
        <button className="search-btn" onClick={handleSearch}>
          OK
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
