// src/pages/AboutPage/AboutPage.tsx
import React from 'react';
import './About.css';

const AboutPage: React.FC = () => {
  return (
    <div className="about-page">
      <h1>À propos de Rintintin</h1>
      <p>
        Bienvenue sur Rintintin, la plateforme dédiée à vos meilleurs amis. Nous sommes là pour vous accompagner dans le bien-être et l’épanouissement de votre compagnon.
      </p>
      <p>
        Notre mission est de simplifier la recherche d’éducateurs canins qualifiés, en vous proposant une interface intuitive et des services adaptés à vos besoins.
      </p>
      <p>
        Nous travaillons exclusivement avec des éducateurs canins professionnels, sélectionnés pour leur expertise et leur passion, afin de garantir des séances efficaces et personnalisées pour chaque chien.
      </p>
    </div>
  );
};

export default AboutPage;
