// src/components/TimePickerComponent/TimePickerComponent.tsx

import React from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

interface TimePickerComponentProps {
  availableTimes: string[];
  selectedTime: string;
  onTimeSelect: (time: string) => void;
}

const TimePickerComponent: React.FC<TimePickerComponentProps> = ({
  availableTimes,
  selectedTime,
  onTimeSelect,
}) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" color="#333" sx={{ mb: 2 }}>
        Choisir une heure
      </Typography>
      {availableTimes.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel id="time-select-label">Sélectionnez une heure</InputLabel>
          <Select
            labelId="time-select-label"
            value={selectedTime}
            onChange={(e) => onTimeSelect(e.target.value)}
            label="Sélectionnez une heure"
          >
            {availableTimes.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography variant="body2" color="text.secondary">
          Aucun créneau horaire disponible pour cette date.
        </Typography>
      )}
    </Box>
  );
};

export default TimePickerComponent;
