/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TextInputField, Pane, Select } from 'evergreen-ui';

interface AddDogStep1Props {
  formData: any;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  breeds: { label: string, value: string }[];
}

const AddDogStep1: React.FC<AddDogStep1Props> = ({ formData, handleInputChange, breeds }) => {
  return (
    <Pane className="step-content">
      <TextInputField
        label="Nom du chien"
        placeholder="Nom du chien"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      <Pane>
        <label>Race</label>
        <select
          name="breed"
          value={formData.breed}
          onChange={handleInputChange}
        >
          <option value="" disabled>Sélectionner une race</option>
          {breeds.map((breed) => (
            <option key={breed.value} value={breed.value}>{breed.label}</option>
          ))}
        </select>
      </Pane>
      <TextInputField
        label="Date de naissance"
        placeholder="Date de naissance"
        type="date"
        name="birthdate"
        value={formData.birthdate}
        onChange={handleInputChange}
      />
      <Pane>
        <label>Sexe</label>
        <select
          name="sexe"
          value={formData.sexe}
          onChange={handleInputChange}
        >
          <option value="" disabled>Sélectionner le sexe</option>
          <option value="Mâle">Mâle</option>
          <option value="Femelle">Femelle</option>
        </select>
      </Pane>
      <TextInputField
        label="Photo de profil"
        placeholder="Photo de profil"
        name="profile_photo"
        type="file"
        onChange={handleInputChange}
      />
    </Pane>
  );
};

export default AddDogStep1;
