// src/components/FAQ/FAQ.tsx

import React, { useState, useEffect, useRef } from 'react';
import './FAQ.css';
import { Link } from 'react-router-dom';
import dogImage from '../../assets/dog-image.jpg';

interface FAQItem {
  question: string;
  answer: string;
}

const faqItems: FAQItem[] = [
  { question: 'Comment puis-je être référencé sur Rintintin ?', answer: "Contactez-nous par mail à l'adresse contact@rintintin-booking.com en nous envoyant les informations vous concernant et on se fera un plaisir de vous référencer gratuitement !" },
  { question: 'Comment puis-je être sûr que mon chien sera en sécurité ?', answer: "Tous les éducateurs sur Rintintin sont vérifiés avant d'être répertoriés. Nous encourageons également les utilisateurs à laisser des avis pour garantir un haut niveau de confiance et de qualité." },
  { question: 'Quels types de services sont proposés par les éducateurs ?', answer: "Nos éducateurs offrent une variété de services, notamment l'apprentissage de la marche en laisse, le rappel, la socialisation, et bien plus. Chaque service est décrit en détail dans le profil de l’éducateur." },
  { question: 'Je suis un professionnel canin, comment puis-je être sur Rintintin ?', answer: "Si vous n'êtes pas sur Rintintin, contactez-nous directement et nous vous accompagnerons dans la création de votre profil !" },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const faqRef = useRef<HTMLDivElement>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const animateOnScroll = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(animateOnScroll, options);

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="faq-container" ref={faqRef}>
      <div className="faq-section">
        <h2>Des réponses à vos questions</h2>
        <div className="faq-items">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question">
                {item.question}
                <span className="faq-icon">{activeIndex === index ? '▲' : '▼'}</span>
              </div>
              {activeIndex === index && <div className="faq-answer">{item.answer}</div>}
            </div>
          ))}
        </div>
      </div>
      <div className="faq-image-section">
        <img src={dogImage} alt="FAQ" className="faq-image" />
        <div className="faq-cta-container">
          <p className="faq-cta-text">POSEZ-NOUS VOS QUESTIONS</p>
          <Link to="/contact">
            <button className="faq-cta-button">ICI</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
